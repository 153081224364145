export default defineNuxtRouteMiddleware((to, from) => {
  if (typeof window !== 'undefined') {
    // @ts-ignore
    const scrollPosition = from?.meta?.scrollPosition;
    if (scrollPosition && window) {
      // @ts-ignore
      scrollPosition.y = window.scrollY || 0;

      const container = document.querySelector('.horizontal-scroll-container ');
      if (container) {
        // @ts-ignore
        scrollPosition.x = container.scrollLeft || 0;
      } else {
        // @ts-ignore
        scrollPosition.x = 0;
      }
    }
  }
});
